
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.customDatePicker, 
.customDatePicker > div.react-datepicker-wrapper, 
.customDatePicker > div > div.react-datepicker__input-container
.customDatePicker > div > div.react-datepicker__input-container input {
   width: 500px;
   font-size: 16px;
   margin-top: 10px;
}

.MuiPaper-rounded {
  border-radius: 0px;
}

.MuiSvgIcon-root{
  color: #333333;
}

div[class^="makeStyles-toolbarIcon-"]  {
  height: 73.5px;
}

.jss3{
  height: 73.5px;
}

